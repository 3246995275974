import { cn } from '@cardo/lib';
import { Flatten, StrapiImage, StrapiImageAttributes } from '@cardo/types';
import { IoPersonCircleOutline } from 'react-icons/io5';

export function UserAvatar({
  avatar,

  className,
}: {
  avatar?: StrapiImage | Flatten<StrapiImage> | StrapiImageAttributes | null;

  className?: string;
}) {
  if (!avatar) {
    return (
      <IoPersonCircleOutline
        className={cn('text-2xl text-gray-600 h-[40px] w-[40px]', className)}
      />
    );
  }

  const image = 'data' in avatar ? avatar.data : avatar;
  const url = image
    ? 'attributes' in image
      ? image.attributes?.url
      : image?.url
    : '';

  if (!url) {
    return (
      <IoPersonCircleOutline
        className={cn('text-2xl text-gray-600 h-[40px] w-[40px]', className)}
      />
    );
  }

  return (
    <div
      className={cn(
        'flex items-center justify-center overflow-hidden rounded-full border shadow bg-white h-[40px] w-[40px]',

        className,
      )}
    >
      <img
        src={url}
        className={cn('object-contain h-full w-full')}
        alt="user avatar"
      />
    </div>
  );
}
